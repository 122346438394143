import { BigNum } from '@drift-labs/sdk';
import { UISerializableFundingPaymentRecord } from '@drift/common';
import { OrderedPerpMarkets } from 'src/environmentVariables/EnvironmentVariables';

export type FundingHistoryData = {
	market: string;
	size: BigNum;
	datetime: number;
	rate: BigNum;
	accountName: string;
} & UISerializableFundingPaymentRecord;

export const fundingHistorySelector = (
	fundingPayments: UISerializableFundingPaymentRecord[],
	accountName: string
): FundingHistoryData[] => {
	let result: FundingHistoryData[] = [];
	result =
		fundingPayments.map((fundingPayment) => {
			let rate = BigNum.zero();

			if (fundingPayment.baseAssetAmount.gt(BigNum.zero())) {
				rate = fundingPayment.ammCumulativeFundingLong
					.sub(fundingPayment.userLastCumulativeFunding)
					.neg();
			} else {
				rate = fundingPayment.ammCumulativeFundingShort.sub(
					fundingPayment.userLastCumulativeFunding
				);
			}

			return {
				market: OrderedPerpMarkets[fundingPayment.marketIndex].baseAssetSymbol,
				size: fundingPayment.fundingPayment,
				datetime: fundingPayment.ts.toNumber() * 1000,
				rate,
				accountName: accountName,
				...fundingPayment,
			};
		}) ?? [];

	return result;
};
