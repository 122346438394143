'use client';

import { useEffect, useRef } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

type Options = {
	skipFirst?: boolean;
};

const DEFAULT_OPTS: Options = {
	skipFirst: true,
};

const useOnWalletChange = (cb: () => void, opts: Partial<Options> = {}) => {
	const options = {
		...opts,
		...DEFAULT_OPTS,
	};

	const authority = useDriftStore((s) => s.wallet.current.adapter?.publicKey);

	const isFirst = useRef<boolean>(true);

	useEffect(() => {
		if (options.skipFirst && isFirst.current) {
			isFirst.current = false;
			return;
		}

		cb();
		isFirst.current = false;
	}, [authority]);
};

export default useOnWalletChange;
