'use client';

import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';

const FILTER_DEFAULTS = {
	showPerpMarkets: {
		tradePage: true,
		overviewPage: true,
		predictionsPage: false,
	},
	showPredictionMarkets: {
		tradePage: false,
		overviewPage: true,
		predictionsPage: true,
	},
	showSpotMarkets: {
		// only for orders table
		tradePage: true,
		overviewPage: true,
		predictionsPage: false,
	},
};

export const useTableFiltersState = (props: {
	overviewPage?: boolean;
	predictionsPage?: boolean;
}) => {
	const stateKey = props.overviewPage
		? 'overviewPageTableFilters'
		: props.predictionsPage
		? 'predictionsPageTableFilters'
		: 'tradePageTableFilters';

	const setStore = useDriftStore((s) => s.set);
	const tradePageTableFilters = useDriftStore(
		(s) => s[stateKey]
	) as DriftStore['tradePageTableFilters'];

	const showPerpMarketsDefaultValue = props.overviewPage
		? FILTER_DEFAULTS.showPerpMarkets.overviewPage
		: props.predictionsPage
		? FILTER_DEFAULTS.showPerpMarkets.predictionsPage
		: FILTER_DEFAULTS.showPerpMarkets.tradePage;

	const showPredictionMarketsDefaultValue = props.overviewPage
		? FILTER_DEFAULTS.showPredictionMarkets.overviewPage
		: props.predictionsPage
		? FILTER_DEFAULTS.showPredictionMarkets.predictionsPage
		: FILTER_DEFAULTS.showPredictionMarkets.tradePage;

	const showSpotMarketsDefaultValue = props.overviewPage
		? FILTER_DEFAULTS.showSpotMarkets.overviewPage
		: props.predictionsPage
		? FILTER_DEFAULTS.showSpotMarkets.predictionsPage
		: FILTER_DEFAULTS.showSpotMarkets.tradePage;

	const showOnlyCurrentMarket = tradePageTableFilters.showOnlyCurrentMarket;

	const showPerpMarkets =
		tradePageTableFilters.showPerpMarkets === undefined
			? showPerpMarketsDefaultValue
			: tradePageTableFilters.showPerpMarkets;

	const showPredictionMarkets =
		tradePageTableFilters.showPredictionMarkets === undefined
			? showPredictionMarketsDefaultValue
			: tradePageTableFilters.showPredictionMarkets;

	const showSpotMarkets =
		tradePageTableFilters.showSpotMarkets === undefined
			? showSpotMarketsDefaultValue
			: tradePageTableFilters.showSpotMarkets;

	const setShowOnlyCurrentMarket = (newValue: boolean) => {
		setStore((s) => {
			s[stateKey].showOnlyCurrentMarket = newValue;
		});
	};

	const setShowPerpMarkets = (newValue: boolean) => {
		setStore((s) => {
			s[stateKey].showPerpMarkets = newValue;
			s[stateKey].showOnlyCurrentMarket = false;
		});
	};

	const setShowPredictionMarkets = (newValue: boolean) => {
		setStore((s) => {
			s[stateKey].showPredictionMarkets = newValue;
			s[stateKey].showOnlyCurrentMarket = false;
		});
	};

	const setShowSpotMarkets = (newValue: boolean) => {
		setStore((s) => {
			s[stateKey].showSpotMarkets = newValue;
			s[stateKey].showOnlyCurrentMarket = false;
		});
	};

	return {
		showOnlyCurrentMarket,
		showPerpMarkets,
		showPredictionMarkets,
		showSpotMarkets,
		setShowOnlyCurrentMarket,
		setShowPerpMarkets,
		setShowPredictionMarkets,
		setShowSpotMarkets,
	};
};
