'use client';

import EventEmitter from 'events';
import { useEffect } from 'react';
import useAppEventEmitter from './useAppEventEmitter';

const useSharedHook = (
	key: string,
	hook: () => [string, (val: string) => void]
) => {
	const EVENT_KEY = `shared_hook_${key}`;

	const [val, setVal] = hook();

	const eventEmitter = useAppEventEmitter();

	useEffect(() => {
		const handleHookEvent = (newVal: string) => {
			setVal(newVal);
		};

		(eventEmitter as EventEmitter).on(EVENT_KEY, handleHookEvent);

		return () => {
			(eventEmitter as EventEmitter).removeListener(EVENT_KEY, handleHookEvent);
		};
	}, [eventEmitter]);

	const wrappedSetVal = (newVal: string) => {
		(eventEmitter as EventEmitter).emit(EVENT_KEY, newVal);
		setVal(newVal);
	};

	return [val, wrappedSetVal] as [typeof val, typeof setVal];
};

export default useSharedHook;
