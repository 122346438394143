'use client';

import { Edit, ArrowRight, Add } from '@drift-labs/icons';
import {
	BigNum,
	OrderTriggerCondition,
	OrderType,
	PositionDirection,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import {
	UIOrderType,
	matchEnum,
	UISerializableOrder,
	COMMON_UI_UTILS,
} from '@drift/common';
import { ReactNode } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Text from 'src/components/Text/Text';
import Tooltip from 'src/components/Tooltip/Tooltip';
import React from 'react';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
import { AccountOpenPosition } from '../Tables/TableV2';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';

const gridRowClasses =
	'grid grid-flow-row auto-rows-auto gap-2 grid-cols-[minmax(120px,auto)_minmax(120px,auto)_minmax(140px,auto)] pr-3';

const TpSlTooltipRow = ({
	order,
	positionEntryPrice,
	className,
}: {
	order: UISerializableOrder;
	positionEntryPrice: BigNum;
	className: string;
}) => {
	const uiOrderType = UI_UTILS.getUIOrderTypeFromSdkOrderType(
		order.orderType,
		order.triggerCondition,
		order.direction,
		new BigNum(0)
	);

	const orderPrice =
		uiOrderType.value === 'limit' ? order.price : order.triggerPrice;

	const priceDiff = orderPrice.toNum() - positionEntryPrice.toNum();
	const positive = priceDiff >= 0;
	const percentDiff = (priceDiff / positionEntryPrice.toNum()) * 100;

	return (
		<div className={twMerge(gridRowClasses, className)}>
			<Text.BODY3>{uiOrderType.label}</Text.BODY3>
			<Text.BODY3>
				{COMMON_UI_UTILS.trimTrailingZeros(
					order.baseAssetAmountFilled.toTradePrecision()
				)}{' '}
				/{' '}
				{COMMON_UI_UTILS.trimTrailingZeros(
					order.baseAssetAmount.toTradePrecision()
				)}
			</Text.BODY3>
			<Text.BODY3>
				{orderPrice.toNotional()}{' '}
				<span
					className={twMerge(
						positive ? 'text-positive-green' : 'text-negative-red',
						'ml-1'
					)}
				>
					({positive ? '+' : ''}
					{percentDiff.toFixed(2)}%)
				</span>
			</Text.BODY3>
		</div>
	);
};

export const TakeProfitStopLossButton = React.memo(
	function StopLossButtonMemo(props: {
		type: 'takeProfit' | 'stopLoss';
		position: AccountOpenPosition;
		handleOpenEditOrderPopup: (
			targetElementRef: HTMLElement,
			order: UISerializableOrder
		) => void;
		handleOpenClosePositionPopup: (
			popupOrderType: UIOrderType,
			popupType: 'takeProfit' | 'stopLoss' | 'closePosition',
			targetElement: HTMLElement
		) => void;
	}) {
		const isMobile = useIsMobileScreenSize();
		const setStore = useDriftStore((s) => s.set);
		const addTriggerOrderButtonRef = React.useRef<HTMLButtonElement | null>(
			null
		);

		const marketIndex = props.position.marketIndex;

		const entryPriceBigNum = BigNum.from(
			props.position.entryPrice,
			QUOTE_PRECISION_EXP
		);

		const accountKey = useDriftAccountStore((s) => s.currentUserKey);

		const allOrders = useDriftAccountStore((s) => {
			return s.accounts[accountKey]?.openOrders || [];
		});

		const isLong = props.position.direction === 'long';

		const popupTargetId = `${
			props.type
		}_button_${marketIndex.toString()}_${accountKey}`;

		// I think we want to probably make this a hook so it can be unit tested. After the feature is fully working.
		const filteredOrders = allOrders.filter((order) => {
			if (order.marketIndex !== marketIndex) return;

			// Any order in the same direction is not a TP/SL order
			const isOppositeDirection =
				(isLong && matchEnum(order.direction, PositionDirection.SHORT)) ||
				(!isLong && matchEnum(order.direction, PositionDirection.LONG));
			if (!isOppositeDirection) return;

			// Limit orders in opposite direction are always TP orders, if limit price is below the current mark price it will be filled immediately
			const isLimitOrder = matchEnum(order.orderType, OrderType.LIMIT);
			if (isLimitOrder && props.type === 'takeProfit') return true;

			// Trigger orders are TP or SL depending on what side of the oracle price they are on
			const isTriggerOrder =
				matchEnum(order.orderType, OrderType.TRIGGER_MARKET) ||
				matchEnum(order.orderType, OrderType.TRIGGER_LIMIT);

			// All orther order types should not appear here
			if (!isTriggerOrder) {
				return;
			}

			if (props.type === 'takeProfit') {
				const orderIsTpForLong =
					isLong &&
					matchEnum(order.triggerCondition, OrderTriggerCondition.ABOVE);
				const orderIsTpForShort =
					!isLong &&
					matchEnum(order.triggerCondition, OrderTriggerCondition.BELOW);
				return orderIsTpForLong || orderIsTpForShort;
			} else {
				const orderIsSlForLong =
					isLong &&
					matchEnum(order.triggerCondition, OrderTriggerCondition.BELOW);
				const orderIsSlForShort =
					!isLong &&
					matchEnum(order.triggerCondition, OrderTriggerCondition.ABOVE);
				return orderIsSlForLong || orderIsSlForShort;
			}
		});

		const handleEdit = () => {
			props.handleOpenEditOrderPopup(
				addTriggerOrderButtonRef.current,
				filteredOrders[0]
			);
		};

		const handleViewMultiple = () => {
			setStore((s) => {
				s.userInfoTable.currentTab = 'openOrders';
			});
		};

		const handleAddTpOrSl = () => {
			const popupType = props.type;
			const popupOrderType =
				props.type === 'takeProfit'
					? 'takeProfitMarket'
					: ('stopMarket' as UIOrderType);

			props.handleOpenClosePositionPopup(
				popupOrderType,
				popupType,
				addTriggerOrderButtonRef.current
			);
		};

		const addButton = (
			<button
				onClick={() => handleAddTpOrSl()}
				id={popupTargetId}
				ref={addTriggerOrderButtonRef}
			>
				<div className="hover:opacity-70 transition-opacity inline-flex items-center gap-[2px] xs:mt-2 sm:mt-0">
					<Add className="w-3 h-3 mr-1" color={'var(--text-label)'} />
					<Text.BODY2 className="light text-text-label">Add</Text.BODY2>
				</div>
			</button>
		);

		const tooltipContent = (
			<div>
				<div
					className={`${gridRowClasses} pb-1 mb-3 border-b border-text-secondary`}
				>
					<Text.BODY3 className="font-semibold">Order Type</Text.BODY3>
					<Text.BODY3 className="font-semibold">Filled / Size</Text.BODY3>
					<Text.BODY3 className="font-semibold">Price</Text.BODY3>
				</div>
				{filteredOrders.map((order) => (
					<TpSlTooltipRow
						className={'mt-3'}
						order={order}
						key={`${order.orderId}_${order.marketIndex}`}
						positionEntryPrice={entryPriceBigNum}
					/>
				))}
			</div>
		);

		let editButton: ReactNode;
		let multipleOrdersButton: ReactNode;

		if (filteredOrders.length === 1) {
			const order = filteredOrders[0];
			const price = matchEnum(order.orderType, OrderType.LIMIT)
				? order.price
				: order.triggerPrice;

			editButton = (
				<Tooltip
					content={tooltipContent}
					placement={'top'}
					allowHover
					disabled={isMobile}
				>
					<div className="flex flex-row items-center xs:mt-2 sm:mt-0">
						<button onClick={handleEdit}>
							<div className="hover:opacity-70 transition-opacity inline-flex items-center gap-[2px]">
								<Edit
									className="xs:w-4 xs:h-4 sm:w-3 sm:h-3 mr-1 mt-[-2px]"
									color={'var(--text-label)'}
								/>{' '}
								<Text.BODY2 light className="text-text-default">
									{price.toNotional()}
								</Text.BODY2>
							</div>
						</button>
					</div>
				</Tooltip>
			);
		} else if (filteredOrders.length > 1) {
			multipleOrdersButton = (
				<Tooltip content={tooltipContent} placement="top" disabled={isMobile}>
					<button
						className="transition-opacity hover:opacity-70 xs:mt-1 sm:mt-0"
						onClick={handleViewMultiple}
					>
						<Text.BODY2 light className="text-text-default">
							{' '}
							{filteredOrders.length} orders
							<ArrowRight
								size={14}
								className="relative top-[3px] ml-1"
								color="var(--text-default)"
							/>
						</Text.BODY2>
					</button>
				</Tooltip>
			);
		}

		return (
			<div className="flex flex-col items-start leading-3">
				{editButton}
				{multipleOrdersButton}
				{addButton}
			</div>
		);
	}
);
