'use client';

import { useLocalStorageStringState } from '../useLocalStorageState';
import useSharedHook from '../useSharedHook';
import {
	TRADE_PAGE_TABLE_DEFAULT_SIZE,
	TRADE_PAGE_TABLE_RESIZE_OPTION,
} from './tradePageTablePreferenceTypes';

const useTradeTableSizePreference = () => {
	return useSharedHook(`TRADE_TABLE_SIZE_PREFERENCE`, () => {
		const [currentSizePreference, setCurrentSizePreference] =
			useLocalStorageStringState(
				'tradePageTableSizePreference',
				`${TRADE_PAGE_TABLE_DEFAULT_SIZE}`
			);

		let valueToReturn: string = TRADE_PAGE_TABLE_DEFAULT_SIZE;

		const storedValueNumber = parseInt(currentSizePreference);

		const storedValueIsNumber =
			currentSizePreference && !isNaN(storedValueNumber);

		if (storedValueIsNumber) {
			valueToReturn = `${storedValueNumber}`;
		} else if (
			!storedValueIsNumber &&
			TRADE_PAGE_TABLE_RESIZE_OPTION[currentSizePreference]
		) {
			// Map old string settings, i.e. "MEDIUM" to the new number settings, if detected:
			valueToReturn = TRADE_PAGE_TABLE_RESIZE_OPTION[currentSizePreference];
			setCurrentSizePreference(`${valueToReturn}`);
		}

		return [`${valueToReturn}`, setCurrentSizePreference];
	});
};

export default useTradeTableSizePreference;
