'use client';

import {
	BigNum,
	MarketType,
	MAX_PREDICTION_PRICE,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import { useCallback, useEffect, useState } from 'react';
import { useTickedAccountData } from 'src/hooks/useAccountData';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftClient from 'src/hooks/useDriftClient';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import useOnWalletChange from '../hooks/useOnWalletChange';
import useShowAccountValues from '../hooks/useShowAccountValues';
import useWalletIsConnected from '../hooks/useWalletIsConnected';
import Button from './Button';
import SecondaryConnectWalletButton from './SecondaryConnectWalletButton';
import TableStateWrapper from './TableStateWrapper';
import TableV2 from './Tables/TableV2';
import Text from './Text/Text';
import Tooltip from './Tooltip/Tooltip';
import UIHelpTextLink from './UIHelpTextLink';
import NumberDisplay from './Utils/NumberDisplay';
import NumberDisplayV2 from './Utils/NumberDisplayV2';
import { COMMON_UI_UTILS, OpenPosition, UIMarket } from '@drift/common';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';
import Select from './Inputs/Select';
import UI_UTILS from 'src/utils/uiUtils';
import { OrderedPerpMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const UnrealizedPnlTable = (props: {
	overviewPage?: boolean;
	predictionsPage?: boolean;
}) => {
	const isMobile = useIsMobileScreenSize();

	const tableGridLayout = `minmax(160px,5fr) minmax(120px,5fr) minmax(130px,5fr) minmax(100px,5fr) minmax(150px,11fr) minmax(80px,6fr)`;

	const set = useDriftAccountStore((s) => s.set);

	const connected = useWalletIsConnected();
	const actions = useDriftActions();
	const driftClient = useDriftClient();
	const showAccountValues = useShowAccountValues();
	const currentAccount = useTickedAccountData();
	const getMarketInfoByIndexAndType = useMarketsInfoStore(
		(s) => s.getMarketInfoByIndexAndType
	);
	const marketsNeedingSettlement =
		currentAccount?.marginInfo?.marketsNeedingSettlement ?? [];
	const isDevMode = useDevSwitchIsOn();
	const checkIsSellPredictionMarket = useDriftStore(
		(s) => s.checkIsSellPredictionMarket
	);

	const getDecimalsForPerpMarket = (marketIndex: number) => {
		const marketInfo = getMarketInfoByIndexAndType(
			marketIndex,
			MarketType.PERP
		);

		return marketInfo.genericInfo.priceDisplayDecimals;
	};

	const [displayPositions, setDisplayPositions] = useState<
		(OpenPosition & {
			accountName: string;
			accountKey: string;
		})[]
	>(undefined);

	useOnWalletChange(() => {
		setDisplayPositions(undefined);
	});

	useEffect(() => {
		if (!currentAccount || !currentAccount?.positionsLoaded) return;

		const normalPositions = currentAccount.openPerpPositions
			.filter(
				(pos) =>
					!pos.unsettledPnl.eq(ZERO) ||
					!pos.baseSize.eq(ZERO) ||
					!pos.lpShares.eq(ZERO)
			)
			.filter((pos) =>
				props.overviewPage
					? true
					: UI_UTILS.applyPredictionsFilter(
							pos.marketIndex,
							props.predictionsPage
					  )
			)
			.map((pos) => {
				return {
					...pos,
					accountName: currentAccount.name,
					accountKey: currentAccount.userKey,
				};
			});

		setDisplayPositions(normalPositions);
	}, [currentAccount?.openPerpPositions, currentAccount?.positionsLoaded]);

	const headers = [
		'market',
		'cost basis',
		'settled',
		'unsettled funding',
		'claimable / unsettled',
		'action',
	];

	const setActiveUser = (userKey: string) => {
		const { userId, userAuthority } =
			COMMON_UI_UTILS.getIdAndAuthorityFromKey(userKey);
		driftClient.switchActiveUser(userId, userAuthority);
		set((s) => {
			s.currentUserKey = userKey;
		});
	};

	const handleClickMarket = useCallback((marketIndex: number) => {
		actions.switchMarket({
			marketIndex,
			marketType: MarketType.PERP,
			goToTradePage: true,
		});
	}, []);

	if (isMobile) {
		return (
			<>
				{!displayPositions || displayPositions.length === 0 ? (
					<div
						className={`flex flex-col justify-center items-center sm:h-full w-full text-center rounded-md`}
					>
						{connected ? (
							<div className="my-8">
								<Text.BODY2 className="capitalize text-text-emphasis">
									No unsettled found
								</Text.BODY2>
							</div>
						) : (
							<div className="sm:pt-8">
								<SecondaryConnectWalletButton />
							</div>
						)}
					</div>
				) : (
					<div className="border rounded-md bg-container-bg border-container-border">
						<div className={`flex flex-col text-text-emphasis`}>
							<div className="flex flex-row items-center justify-between p-3 border-b text-text-label border-container-border">
								<Text.BODY1>All Markets</Text.BODY1>
								<Button.Secondary
									size="SMALL"
									disabled={!displayPositions || !displayPositions.length}
									onClick={actions.settleAllPnls}
								>
									Settle All
								</Button.Secondary>
							</div>
							<div className={`flex flex-col text-text-emphasis`}>
								<div className="overflow-auto thin-scroll">
									{displayPositions?.map((position) => (
										<TableV2.UnsettledPnlTableCard
											onClaim={() => {
												setActiveUser(position.accountKey);
												actions.settlePnl(position.marketIndex);
											}}
											position={position}
											key={`position_${position.marketSymbol}`}
											showAccountValues={showAccountValues}
											isPredictionMarket={
												UIMarket.createPerpMarket(position.marketIndex)
													.isPredictionMarket
											}
										/>
									))}
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}

	return (
		<TableStateWrapper
			records={displayPositions}
			emptyStateText={'No Unsettled found'}
			requireWalletConnect
			requireAccountCreated
			id="unrealised_pnl_table"
			className="overflow-x-auto thin-scroll"
		>
			<TableV2.Skeleton
				noBorder
				top={
					<TableV2.HeaderRow
						grid={tableGridLayout}
						header
						forceBottomBorder
						className="pr-2"
					>
						{headers.map((label) => (
							<TableV2.HeaderCell
								className={`${
									props.overviewPage ? 'bg-main-bg' : ''
								} capitalize`}
								key={`header_${label}`.replace(/ /g, '')}
							>
								{label == 'claimable / unsettled' ? (
									<Tooltip
										content={
											<div className="flex flex-col space-y-2">
												<span>
													Claimable P&L is the portion of your position&apos;s
													Unsettled P&L that can be settled. Positive P&L is
													settled to your account balance and negative P&L is
													settled to the per-market pool of funds.
												</span>
												<UIHelpTextLink
													text="Learn more"
													href="https://docs.drift.trade/profit-loss/profit-loss-intro#settling-and-claiming-your-pl"
												/>
											</div>
										}
										allowHover
									>
										{label}
									</Tooltip>
								) : (
									<>{label}</>
								)}
							</TableV2.HeaderCell>
						))}
					</TableV2.HeaderRow>
				}
				middle={
					<div className="w-full sm:max-h-full text-xs xs:max-h-[40vh]">
						{displayPositions?.map((position, index) => {
							const uiMarket = UIMarket.createPerpMarket(position.marketIndex);
							const isSellPredictionMarket = checkIsSellPredictionMarket({
								isPredictionMarket: uiMarket.isPredictionMarket,
								isSellSide: position.direction === 'short',
							});

							return (
								<TableV2.BodyRow
									key={`${index}_${position.marketIndex}`}
									grid={isMobile ? undefined : tableGridLayout}
									className={`text-xs`}
								>
									{uiMarket.isPredictionMarket ? (
										<TableV2.OpenPredictionPositionIdCell
											marketIndex={position.marketIndex}
											direction={position.direction}
											title={
												PredictionMarketConfigs.get(position.marketIndex).title
											}
										/>
									) : (
										<TableV2.MarketCell
											key={`market__${position.marketSymbol}_${index}`}
											className="font-numeral"
											baseAssetSymbol={
												OrderedPerpMarkets[position.marketIndex].baseAssetSymbol
											}
											marketSymbol={position.marketSymbol}
											onClick={() => handleClickMarket(position.marketIndex)}
										/>
									)}

									<TableV2.BodyCell key={`costbasis_${index}`}>
										<NumberDisplayV2
											displayType="notional"
											value={BigNum.from(
												isSellPredictionMarket
													? MAX_PREDICTION_PRICE.sub(position.costBasis)
													: position.costBasis,
												PRICE_PRECISION_EXP
											)}
											subType="$"
											textOverride={!showAccountValues && '∗∗∗∗∗∗'}
											toFixed={getDecimalsForPerpMarket(position.marketIndex)}
											trimZeroes={2}
										/>
									</TableV2.BodyCell>
									<TableV2.BodyCell key={`realizedpnl_${index}`}>
										<NumberDisplay
											displayType="notional"
											value={BigNum.from(
												position.realizedPnl,
												QUOTE_PRECISION_EXP
											).toNum()}
											subType="$"
											colourCoded
											textOverride={!showAccountValues && '∗∗∗∗∗∗'}
										/>
									</TableV2.BodyCell>
									<TableV2.BodyCell
										className="items-center"
										key={`unsettledfunding_${index}`}
									>
										<NumberDisplay
											className="inline-flex mr-1"
											displayType="notional"
											value={BigNum.from(
												position.unsettledFundingPnl,
												QUOTE_PRECISION_EXP
											).toNum()}
											subType="$"
											colourCoded
											textOverride={!showAccountValues && '∗∗∗∗∗∗'}
										/>
									</TableV2.BodyCell>
									<TableV2.BodyCell
										className="inline-flex items-center"
										key={`availablepnl_${index}`}
									>
										<NumberDisplay
											className="inline-flex mr-1"
											displayType="notional"
											value={BigNum.from(
												position.unsettledPnl,
												QUOTE_PRECISION_EXP
											).toNum()}
											subType="$"
											colourCoded
											textOverride={!showAccountValues && '∗∗∗∗∗∗'}
										/>
										{'/'}
										<NumberDisplay
											className="inline-flex ml-1"
											displayType="notional"
											value={BigNum.from(
												position.totalUnrealizedPnl,
												QUOTE_PRECISION_EXP
											).toNum()}
											subType="$"
											textOverride={!showAccountValues && '∗∗∗∗∗∗'}
										/>
									</TableV2.BodyCell>
									<TableV2.BodyCell key={`settlepnl_${index}`}>
										<Tooltip
											content={
												// !position.pnlIsClaimable
												// 	? 'P&L claim is currently disabled due to invalid oracle.'
												// 	:
												'Claim your unsettled funding and P&L. This will not impact your open positions.'
											}
										>
											<Button.Secondary
												size="SMALL"
												disabled={
													position.unsettledPnl.isZero() /*||
														!position.pnlIsClaimable*/ && !isDevMode
												}
												onClick={() => {
													setActiveUser(position.accountKey);
													actions.settlePnl(position.marketIndex);
												}}
											>
												Settle
											</Button.Secondary>
										</Tooltip>
									</TableV2.BodyCell>
								</TableV2.BodyRow>
							);
						})}
					</div>
				}
				bottom={
					<div className={`items-center w-full flex justify-end p-1`}>
						{marketsNeedingSettlement?.length > 0 && (
							<div className="max-w-[25%] mr-2">
								<Select.Settle
									options={marketsNeedingSettlement.map(
										(mktIndex) => OrderedPerpMarkets[mktIndex]
									)}
								/>
							</div>
						)}
						<Tooltip content="Claim your unsettled funding and P&L. This will not impact your open positions.">
							<Button.Secondary
								size="SMALL"
								disabled={!displayPositions || !displayPositions.length}
								onClick={actions.settleAllPnls}
							>
								Settle All
							</Button.Secondary>
						</Tooltip>
					</div>
				}
			/>
		</TableStateWrapper>
	);
};

export default UnrealizedPnlTable;
