'use client';

import React from 'react';
import Switch from './Switch';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import { twMerge } from 'tailwind-merge';
import Text from './Text/Text';

const PositionLinesAndOrdersToggle = (props: { className?: string }) => {
	const [currentSettings, updateSettings] = useCurrentSettings();

	const handleChangeSetting = () => {
		updateSettings({
			...currentSettings,
			showPositionsAndOrdersOnChart:
				!currentSettings.showPositionsAndOrdersOnChart,
		});
	};

	return (
		<span
			className={twMerge(
				'flex flex-row items-center text-text-label space-x-2 p-2',
				props.className
			)}
			onClick={handleChangeSetting}
		>
			<Text.BODY3>Order & Position Lines</Text.BODY3>
			<Switch
				checked={currentSettings.showPositionsAndOrdersOnChart}
				onChange={handleChangeSetting}
			/>
		</span>
	);
};

export default React.memo(PositionLinesAndOrdersToggle);
