'use client';

import { PropsWithChildren, useState } from 'react';
import ReactDOM from 'react-dom';
import { Info } from '@drift-labs/icons';
import XButton from '../Buttons/XButton';

const DevTooltip = (
	props: PropsWithChildren<{ x?: 'left' | 'right'; y?: 'top' | 'bottom' }>
) => {
	const [toggledOn, setToggledOn] = useState(false);
	const [hoverOn, setHoverOn] = useState(false);

	const handleMouseEnter = () => {
		setHoverOn(true);
	};

	const handleMouseLeave = () => {
		setHoverOn(false);
	};

	const handleClick = () => {
		setToggledOn(!toggledOn);
	};

	const x = props.x ?? 'left';
	const y = props.y ?? 'top';

	const postionStyle = {
		top: y === 'top' ? 0 : undefined,
		bottom: y === 'bottom' ? 0 : undefined,
		left: x === 'left' ? 0 : undefined,
		right: x === 'right' ? 0 : undefined,
	};

	const showContent = hoverOn || toggledOn;

	return (
		<>
			<span
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<Info
					height={18}
					width={18}
					color={toggledOn ? 'red' : hoverOn ? 'orange' : undefined}
				/>
			</span>
			{showContent &&
				ReactDOM.createPortal(
					<div
						style={{
							zIndex: 100,
							position: 'absolute',
							...postionStyle,
						}}
					>
						<div className="relative p-4 text-white break-normal bg-darkBlue-90">
							<XButton
								className="absolute top-0 right-0 w-6 h-6"
								onClick={() => {
									setToggledOn(false);
								}}
							/>
							<span>{props.children}</span>
						</div>
					</div>,
					document.body
				)}
		</>
	);
};

export default DevTooltip;
