'use client';

import { BN, ZERO } from '@drift-labs/sdk';
import useLastSeenLiqidationTsForWallet from 'src/hooks/useLastSeenLiqidationTs';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	TRADE_PAGE_TABLE_DEFAULT_SIZE,
	TRADE_PAGE_TABLE_RESIZE_OPTION,
} from '../../hooks/tradePageTablePreference/tradePageTablePreferenceTypes';
import useTradeTableSizePreference from '../../hooks/tradePageTablePreference/useTradeTableSizePreference';
import useDriftAccountStore from '../../stores/useDriftAccountsStore';
import FundingHistoryTable from '../FundingHistoryTable';
import OpenOrdersTable from '../OpenOrdersTable';
import OpenPositionsTable from '../OpenPositionsTable';
import HighlightedTabs from '../TabbedNavigation/HighlightedTabs';
import TradeHistoryTable from '../TradeHistoryTable';
import UserBalancesPortfolioTable from '../UserBalancesPortfolioTable/UserBalancesPortfolioTable';
import { LiteModeTradePageTableResizer } from './TradePageTableResizer';
import MarginInfo from '../MarginInfo/MarginInfo';
import React, { useEffect, useRef } from 'react';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useIsTabletOrSmallerSize from 'src/hooks/useIsTabletOrSmallerSize';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useUserAccountIsReady from 'src/hooks/useUserAccountIsReady';
import GradientText from '../Utils/GradientText';
import { Heart } from '@drift-labs/icons';
import {
	ACCOUNT_HEALTH_PERCENT_CRITICAL,
	ACCOUNT_HEALTH_PERCENT_WARNING,
	PageRoute,
} from 'src/constants/constants';
import PositionLinesAndOrdersToggle from '../PositionLinesAndOrdersToggle';
import { twMerge } from 'tailwind-merge';
import MobileScrollEdges from '../MobileScrollEdges';
import UnrealizedPnlTable from '../UnrealizedPnlTable';
import UI_UTILS from 'src/utils/uiUtils';
// import PredictionMarketsOpenPositionsTable from '../PredictionMarkets/PredictionMarketsOpenPositionsTable';
import { UIMarket } from '@drift/common';

const AccountTabLabel = (props: { selected: boolean }) => {
	const connected = useWalletIsConnected();

	const userAccountIsReady = useUserAccountIsReady();
	const userClient = useDriftAccountStore((s) => {
		return s.accounts[s.currentUserKey]?.client;
	});

	const health =
		connected && userAccountIsReady && userClient
			? userClient.getHealth()
			: 100;

	const heartColor =
		health < ACCOUNT_HEALTH_PERCENT_CRITICAL
			? 'var(--negative-red)'
			: health < ACCOUNT_HEALTH_PERCENT_WARNING
			? 'var(--warn-yellow)'
			: 'var(--positive-green)';

	return (
		<div className="flex flex-row items-center space-x-1">
			<GradientText disabled={!props.selected}>
				<span>{'Account'}</span>
			</GradientText>
			<Heart color={heartColor} size={20} className="relative sm:top-[-1px]" />
		</div>
	);
};

const TradePageTableTabs = ({
	activeTab,
	setActiveTab,
	positionCount,
	openOrderCount,
	balanceCount,
	overviewPage,
	predictionPage,
	hideMinimiser,
}: //onLiqClick,
{
	activeTab: string;
	setActiveTab: (newTab: string) => void;
	positionCount: number;
	unsettledPnlCount: number;
	liqCount: number;
	openOrderCount: number;
	balanceCount: number;
	overviewPage?: boolean;
	predictionPage?: boolean;
	onLiqClick?: () => void;
	hideMinimiser;
}) => {
	const isMobileScreenSize = useIsMobileScreenSize();
	const isTabletScreenSizeOrSmaller = useIsTabletOrSmallerSize();
	const isLiteMode = useIsLiteMode();
	const [tableSizePreference, setTableSizePreference] =
		useTradeTableSizePreference();

	const isMinimized =
		tableSizePreference === TRADE_PAGE_TABLE_RESIZE_OPTION.MINIMIZE;

	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);

	const tableTabsRef = useRef<HTMLDivElement>(null);

	const selectedMarketIsPerp = !selectedMarketId || selectedMarketId.isPerp;

	let tradePageOptions = [
		{
			value: 'balances',
			label: 'Balances',
			numberIndicator: balanceCount,
			showOnMobile: true,
			showOnTablet: true,
			showOnDesktop: true,
		},
		{
			value: 'positions',
			label: 'Positions',
			numberIndicator: positionCount,
			showOnMobile: true,
			showOnTablet: true,
			showOnDesktop: true,
		},
	];
	tradePageOptions = selectedMarketIsPerp
		? tradePageOptions.reverse()
		: tradePageOptions;

	const tabOptions: {
		value: string;
		label: string | React.ReactNode;
		showOnMobile: boolean;
		showOnTablet: boolean;
		showOnDesktop: boolean;
		numberIndicator?: number;
	}[] = predictionPage
		? [
				{
					value: 'positions',
					label: `Positions`,
					numberIndicator: positionCount,
					showOnMobile: true,
					showOnTablet: true,
					showOnDesktop: true,
				},
				{
					value: 'openOrders',
					label: 'Orders',
					numberIndicator: openOrderCount,
					showOnMobile: true,
					showOnTablet: true,
					showOnDesktop: true,
				},
				{
					value: 'tradeHistory',
					label: 'History',
					showOnMobile: true,
					showOnTablet: true,
					showOnDesktop: true,
				},
				{
					value: 'balances',
					label: 'Balances',
					numberIndicator: balanceCount,
					showOnMobile: false,
					showOnTablet: true,
					showOnDesktop: true,
				},
		  ]
		: overviewPage
		? [
				{
					value: 'balances',
					label: 'Balances',
					numberIndicator: balanceCount,
					showOnMobile: false,
					showOnTablet: true,
					showOnDesktop: true,
				},
				{
					value: 'positions',
					label: `Positions`,
					numberIndicator: positionCount,
					showOnMobile: true,
					showOnTablet: true,
					showOnDesktop: true,
				},
				{
					value: 'unsettled',
					label: `Unsettled`,
					showOnMobile: true,
					showOnTablet: true,
					showOnDesktop: true,
				},
		  ]
		: [
				tradePageOptions[0],
				{
					value: 'openOrders',
					label: 'Orders',
					numberIndicator: openOrderCount,
					showOnMobile: true,
					showOnTablet: true,
					showOnDesktop: true,
				},
				{
					value: 'tradeHistory',
					label: 'Trades',
					showOnMobile: true,
					showOnTablet: true,
					showOnDesktop: true,
				},
				tradePageOptions[1],
		  ].filter((option) => {
				if (isMobileScreenSize) {
					return option.showOnMobile;
				} else if (isTabletScreenSizeOrSmaller || isLiteMode) {
					return option.showOnTablet;
				} else {
					return option.showOnDesktop;
				}
		  });

	const accountTabOption = {
		value: 'account',
		label: <AccountTabLabel selected={activeTab === 'account'} />,
		numberIndicator: 0,
		showOnMobile: true,
		showOnTablet: true,
		showOnDesktop: true,
	};

	if (!overviewPage && !predictionPage) {
		if (isMobileScreenSize) {
			tabOptions.unshift(accountTabOption);
		} else {
			tabOptions.push(accountTabOption);
		}
	}

	const resetTabIfNotFound = () => {
		if (!tabOptions.find((option) => option.value === activeTab)) {
			setActiveTab('positions');
		}
	};

	useEffect(() => {
		resetTabIfNotFound();
	}, [
		isMobileScreenSize,
		isTabletScreenSizeOrSmaller,
		selectedMarketId,
		selectedMarketIsPerp,
		isLiteMode,
	]);

	return (
		<MobileScrollEdges className="relative w-full" scrollableRef={tableTabsRef}>
			<div
				id="trade_page_table_tabs"
				className={twMerge(
					'relative flex items-center justify-between w-full overflow-auto border-b border-container-border',
					isMobileScreenSize ? 'hidden-scroll' : 'thin-scroll'
				)}
				ref={tableTabsRef}
			>
				<HighlightedTabs
					currentSelection={activeTab}
					options={tabOptions}
					onChange={(selection) => {
						setActiveTab(selection);
						if (isMinimized) {
							setTableSizePreference(TRADE_PAGE_TABLE_DEFAULT_SIZE);
						}
					}}
					opts={{
						noBorder: true,
						className: 'whitespace-nowrap [&>div]:py-2',
					}}
				/>

				{!overviewPage && !predictionPage && !isMobileScreenSize && (
					<div className="flex flex-row items-center">
						<PositionLinesAndOrdersToggle className="whitespace-nowrap" />
						{!isTabletScreenSizeOrSmaller && !hideMinimiser && (
							<span className="pr-3">
								<LiteModeTradePageTableResizer />
							</span>
						)}
					</div>
				)}
			</div>
		</MobileScrollEdges>
	);
};

const TabContent = ({
	activeTab,
	overviewPage,
	predictionsPage = false,
}: {
	activeTab: string;
	overviewPage?: boolean;
	predictionsPage?: boolean;
}) => {
	switch (activeTab) {
		case 'tradeHistory':
			return (
				<TradeHistoryTable page={predictionsPage ? 'predictions' : 'trade'} />
			);
		case 'fundingHistory':
			return <FundingHistoryTable />;
		case 'openOrders':
			return <OpenOrdersTable predictionsPage={predictionsPage} />;
		case 'balances':
			return (
				<UserBalancesPortfolioTable expanded={false} className="p-4 sm:p-0" />
			);
		case 'unsettled':
			return (
				<UnrealizedPnlTable
					overviewPage={overviewPage}
					predictionsPage={predictionsPage}
				/>
			);
		// Account tab display on mobile + tablet + lite mode
		case 'account':
			return <MarginInfo expanded />;
		default:
			return (
				<OpenPositionsTable
					small={overviewPage}
					overviewPage={overviewPage}
					predictionsPage={predictionsPage}
				/>
			);
	}
};

const useNonzeroOpenPerpPositionCount = (
	showAll?: boolean,
	predictionMarketsOnly?: boolean,
	includeUnsettled?: boolean
) => {
	const openPerpPositionsCount = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const positions = s.accounts?.[currentAccountKey]?.openPerpPositions;

		if (!positions) return 0;

		const nonZeroOpenPerpPositions = positions?.filter((position) => {
			if (!position.baseSize.eq(ZERO)) return true;
			if (includeUnsettled) return !position.unsettledPnl.eq(ZERO);
			return false;
		});

		// if overview page, show all positions
		if (showAll) {
			return nonZeroOpenPerpPositions.length;
		}

		// if trade page, only show non-prediction market positions. if predition page only show prediction positions
		return nonZeroOpenPerpPositions.filter((position) => {
			const uiMarket = UIMarket.createPerpMarket(position.marketIndex);

			return predictionMarketsOnly
				? uiMarket.isPredictionMarket
				: !uiMarket.isPredictionMarket;
		}).length;
	});

	return openPerpPositionsCount;
};

const useBalanceCount = () => {
	const balanceCount = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		return s.accounts[currentAccountKey]?.balanceCount;
	});
	return balanceCount;
};

const useLiquidations = () => {
	const liquidations = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		return s.accounts[currentAccountKey]?.liquidationHistory?.liquidations;
	});
	return liquidations;
};

const useOpenOrderCount = (
	showAll: boolean,
	predictionMarketsOnly: boolean
) => {
	const openOrderCount = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];
		return (
			(currentAccount?.openOrders.filter((order) =>
				showAll
					? true
					: UI_UTILS.applyPredictionsFilter(
							order.marketIndex,
							predictionMarketsOnly
					  )
			).length ?? 0) +
			(currentAccount?.openPerpPositions?.filter(
				(pos) =>
					!pos.lpShares.eq(ZERO) &&
					UI_UTILS.applyPredictionsFilter(
						pos.marketIndex,
						predictionMarketsOnly
					)
			)?.length ?? 0)
		);
	});
	return openOrderCount;
};

const ADJUSTABLE_TABLE_SIZE_PAGE_ROUTES: (PageRoute | '')[] = [PageRoute.trade];

const TradePageTable = ({
	hideMinimiser,
	overviewPage,
	predictionPage = false,
}: {
	hideMinimiser?: boolean;
	overviewPage?: boolean;
	predictionPage?: boolean;
}) => {
	const isMobile = useIsMobileScreenSize();
	const isTabletScreenSizeOrSmaller = useIsTabletOrSmallerSize();
	const resizeTableAreaRef = useRef<HTMLDivElement>(null);
	const setState = useDriftStore((s) => s.set);
	const currentPageRoute = useDriftStore((s) => s.currentPageRoute);

	const setUserInfoTab = (newTab: string) =>
		setState((s) => {
			s.userInfoTable.currentTab = newTab;
		});

	const positionCount = useNonzeroOpenPerpPositionCount(
		overviewPage,
		predictionPage,
		false
	);

	const unsettledCount = useNonzeroOpenPerpPositionCount(
		overviewPage,
		predictionPage,
		true
	);

	const activeTab = useDriftStore((s) => s.userInfoTable.currentTab);

	const liquidations = useLiquidations();

	const openOrderCount = useOpenOrderCount(overviewPage, predictionPage);

	const balanceCount = useBalanceCount();

	const [lastSeenLiqTs, setLastSeenLiqTs] = useLastSeenLiqidationTsForWallet();

	const liqCount =
		lastSeenLiqTs && liquidations
			? liquidations.filter((liq) => liq.ts.gt(new BN(lastSeenLiqTs))).length
			: liquidations?.length ?? 0;

	const [tradePageTableSize] = useTradeTableSizePreference();

	const expandedTableSize = `${tradePageTableSize}px`;
	const isTableAdjustablePage =
		ADJUSTABLE_TABLE_SIZE_PAGE_ROUTES.includes(currentPageRoute);

	useEffect(() => {
		if (resizeTableAreaRef.current) {
			resizeTableAreaRef.current.style.height = isMobile
				? 'auto'
				: isTabletScreenSizeOrSmaller
				? `300px`
				: isTableAdjustablePage
				? expandedTableSize
				: '100%';
		}
	}, [
		resizeTableAreaRef.current,
		expandedTableSize,
		isMobile,
		isTabletScreenSizeOrSmaller,
		isTableAdjustablePage,
	]);

	return (
		<div className="relative flex flex-col h-full">
			<TradePageTableTabs
				activeTab={activeTab}
				setActiveTab={setUserInfoTab}
				positionCount={positionCount}
				unsettledPnlCount={unsettledCount}
				liqCount={liqCount}
				openOrderCount={openOrderCount}
				balanceCount={balanceCount}
				overviewPage={overviewPage}
				predictionPage={predictionPage}
				onLiqClick={() => {
					setLastSeenLiqTs(Math.floor(Date.now() / 1000));
				}}
				hideMinimiser={hideMinimiser}
			/>
			{overviewPage ? (
				<div className="flex-grow">
					<TabContent
						activeTab={activeTab}
						overviewPage={overviewPage}
						predictionsPage={predictionPage}
					/>
				</div>
			) : (
				<div className="overflow-hidden" ref={resizeTableAreaRef}>
					<TabContent
						activeTab={activeTab}
						overviewPage={overviewPage}
						predictionsPage={predictionPage}
					/>
				</div>
			)}
		</div>
	);
};

export default React.memo(TradePageTable);
