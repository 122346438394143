'use client';

import { useLocalStorage } from 'react-use';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';

const useLastSeenLiqidationTs = () => {
	const wallet = useCurrentWalletAdapter();

	return useLocalStorage(
		`${wallet?.publicKey?.toString() ?? ''}_lastSeenLiqTs`,
		0
	);
};

export default useLastSeenLiqidationTs;
