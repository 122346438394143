'use client';
import { BigNum, OrderActionExplanation } from '@drift-labs/sdk';
import { matchEnum } from '@drift/common';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import UI_UTILS from 'src/utils/uiUtils';
import TableV2 from './Tables/TableV2';
import Text from './Text/Text';
import NumberDisplayV2 from './Utils/NumberDisplayV2';
import DevTooltip from './DevTooltip/DevTooltip';
import {
	HistoryRowCommonProps,
	TradeHistoryRowActions,
} from './TradeHistoryTable';

export const TradePageHistoryRow = (
	props: HistoryRowCommonProps & {
		marketSymbol: string;
		decimals: number;
		devSwitch: boolean;
		entryPriceBigNum: BigNum;
		stringifiedTradeRecord: string;
		txSig: string;
		isDataRow?: boolean;
		counterparty: string | undefined;
		marketType: string;
	}
) => {
	const isMobile = useIsMobileScreenSize();

	return (
		<TableV2.BodyRow
			grid={props.tableGrid}
			isDataRow={props.isDataRow}
			className="pt-0 mt-0 border-b rounded-none border-container-border text-text-emphasis"
		>
			<TableV2.MarketCell
				baseAssetSymbol={props.baseAssetSymbol}
				marketSymbol={props.marketName}
				isSpot={props.isSpot}
				direction={
					props.isSpot || props.isPredictionMarket
						? props.isLong
							? 'buy'
							: 'sell'
						: props.isLong
						? 'long'
						: 'short'
				}
				suffix={
					matchEnum(props.actionExplanation, OrderActionExplanation.LIQUIDATION)
						? '(liq.)'
						: matchEnum(
								props.actionExplanation,
								OrderActionExplanation.DERISK_LP
						  )
						? '(BAL auto-derisk)'
						: ''
				}
			/>
			<TableV2.BodyCell className="font-numeral">
				<div className="flex flex-col">
					<span>
						{props.isSpot ? (
							<>
								{props.showAccountValues
									? props.baseAssetAmountFilled.prettyPrint()
									: '∗∗∗∗'}{' '}
								{props.baseAssetSymbol}
							</>
						) : (
							UI_UTILS.baseSizeDisplayForPerpMarket(
								props.baseAssetAmountFilled,
								props.marketSymbol,
								!props.showAccountValues,
								props.decimals
							)
						)}
					</span>
					{isMobile && (
						<Text.MICRO1 className="text-text-secondary">
							{props.showAccountValues
								? UI_UTILS.isNotionalDust(props.quoteAssetAmountFilled)
									? '< $0.01'
									: props.quoteAssetAmountFilled.toNotional()
								: '$ ∗∗∗∗∗∗'}
						</Text.MICRO1>
					)}
				</div>
			</TableV2.BodyCell>
			<TableV2.BodyCell className="font-numeral">
				<NumberDisplayV2
					displayType="notional"
					subType="$"
					toTradePrecision
					value={props.entryPriceBigNum}
				/>
			</TableV2.BodyCell>
			{!isMobile && (
				<TableV2.BodyCell className="font-numeral">
					<div>
						{props.showAccountValues
							? UI_UTILS.isNotionalDust(props.quoteAssetAmountFilled)
								? '< $0.01'
								: props.quoteAssetAmountFilled.toNotional()
							: '$ ∗∗∗∗∗∗'}
					</div>
				</TableV2.BodyCell>
			)}
			<TableV2.BodyCell className="font-numeral">
				{props.fee?.toTradePrecision() ?? '0.00'} USDC
			</TableV2.BodyCell>
			<TableV2.DateCell
				className="text-left font-numeral"
				date={new Date(props.ts.toNumber() * 1000)}
				displayAsTimestamp={props.displayDatesAsTimestamp}
			/>
			<TableV2.BodyCell>
				<TradeHistoryRowActions
					txSig={props.txSig}
					counterparty={props.counterparty}
					marketType={props.marketType}
					actionExplanation={props.actionExplanation}
					isTaker={props.isTaker}
				/>
			</TableV2.BodyCell>
			{props.devSwitch && (
				<span className="absolute right-0">
					<DevTooltip>
						<span className="flex flex-col">
							<span>
								Base Amount Filled : {props.baseAssetAmountFilled.print()}
							</span>
							<span>
								Quote Amount Filled : {props.quoteAssetAmountFilled.print()}
							</span>
							<span>----</span>
							{props.stringifiedTradeRecord.split(',').map((a) => (
								<span className="text-xs" key={a}>
									{a}
								</span>
							))}
						</span>
					</DevTooltip>
				</span>
			)}
		</TableV2.BodyRow>
	);
};
